/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "login.title",
    defaultMessage: "Login..."
  },
  labelEmail: {
    id: "login.label.email",
    defaultMessage: "E-mail"
  },
  labelEmailHelper: {
    id: "login.label.email.helper",
    defaultMessage: "pol@politie.be"
  },
  labelPassword: {
    id: "login.label.password",
    defaultMessage: "Password"
  },
  button: {
    id: "login.button",
    defaultMessage: "Login"
  }
});
