import { defineMessages } from "react-intl";

export default defineMessages({
  firstName: {
    id: "editParty.firstName.title",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "editParty.label.lastName",
    defaultMessage: 'Last Name'
  },
  licensePlate: {
    id: "editParty.label.licensePlate",
    defaultMessage: "Liceseplate"
  },
  carType: {
    id: "editParty.label.typeCar",
    defaultMessage: "Car Type"
  },
});