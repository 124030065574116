import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import { json } from "json-mobx";

import * as turf from "@turf/turf";
import { latLngToArray } from "utils/leaflet-utils";

import { types } from "mobx-state-tree";
import { type } from "os";

export const STATUS = Object.freeze({
  SEARCH: 0,
  ADD_LINE: 1,
  ADD_ZERO_LINE: 2,
  ADD_MARKER_LINE: 3,
  ADD_ITEMS: 4
});
/*
export const BaselineStore = types
  .model("BaselineStore", {
    status: types.number,
    positions: types.array(types.array(types.number)),
    startPerpendicular: types.array(types.array(types.number)),
    endPerpendicular: types.array(types.array(types.number)),
    zeroPoint: types.array(types.array(types.number))
  })
  .actions(self => ({
    setPositions(positions) {
      console.log("setPositions", positions);
      self.positions = positions;
    },
    setStatus(status) {
      self.status = status;
    },
    setPreviewPerpendicular(start, end) {
      self.startPerpendicular = start;
      self.endPerpendicular = end;
    },
    clearPreviewPerpendicular() {
      self.startPerpendicular = null;
      self.endPerpendicular = null;
    },
    setZeroPoint() {
      const position = {
        lat: self.startPerpendicular[0],
        lng: self.startPerpendicular[1]
      };
      self.zeroPoint = position;
    },
    clearZeroPoint() {
      self.zeroPoint = null;
    },
    setInitialPoints(latLng) {
      const positions = getLineStartPoints(latLng);
      self.setPositions(positions);
    }
  }))
  .views(self => ({
    get extendedLine() {
      const [position1, position2] = self.positions;
      console.log([position1, position2]);
      if (position1 && position2) {
        return extendLine(position1, position2, 1);
      }
      return null;
    }
  }));

*/
export default class BaselineStore {
  @persist
  @observable
  status = STATUS.SEARCH;

  @persist("list")
  @observable
  positions = [];

  @observable
  startPerpendicular;

  @observable
  endPerpendicular;

  @persist("object")
  @observable
  zeroPoint;

  @action.bound
  setPositions(positions) {
    this.positions = positions;
  }

  @action.bound
  setStatus(status) {
    this.status = status;
  }

  @action.bound
  setPreviewPerpendicular(start, end) {
    this.startPerpendicular = start;
    this.endPerpendicular = end;
  }

  @action.bound
  clearPreviewPerpendicular() {
    this.startPerpendicular = null;
    this.endPerpendicular = null;
  }

  @action.bound
  setZeroPoint() {
    const position = {
      lat: this.startPerpendicular[0],
      lng: this.startPerpendicular[1]
    };
    this.zeroPoint = position;
  }

  @action.bound
  clearZeroPoint() {
    this.zeroPoint = null;
  }

  @action.bound
  setInitialPoints(latLng) {
    const positions = getLineStartPoints(latLng);
    this.setPositions(positions);
  }

  @computed
  get extendedLine() {
    const [position1, position2] = this.positions;
    if (position1 && position2) {
      return extendLine(position1, position2, 1);
    }
    return null;
  }
}

const extendLine = (position1, position2, distance) => {
  const bearing = turf.rhumbBearing(position1, position2);
  const point1 = extendPosition(position1, bearing, distance);
  const point2 = extendPosition(position2, bearing + 180, distance);

  return { point1, point2 };
};

const extendPosition = (
  position,
  bearing,
  distance,
  options = { units: "kilometers" }
) => {
  var turfPoint1 = turf.point(position);

  const {
    geometry: { coordinates }
  } = turf.destination(turfPoint1, distance, bearing, options);

  return coordinates;
};

const getLineStartPoints = latLng => {
  const currentPosition = latLngToArray(latLng);
  const centerPoint = turf.point(currentPosition);

  const distance = 50;
  const options = { units: "meters" };

  const {
    geometry: { coordinates: startPoint }
  } = turf.destination(centerPoint, distance, 0, options);

  const {
    geometry: { coordinates: endPoint }
  } = turf.destination(centerPoint, distance, 180, options);

  return [startPoint, endPoint];
};
