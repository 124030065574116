import React, { useContext, useEffect, useRef } from "react";
import { Marker, withLeaflet, Polyline, Tooltip } from "react-leaflet";
import { observer as hooksObserver } from "mobx-react-lite";

import { StoresContext } from "contexts";
import { draggableWaypointIcon } from "./icon";
import { latLngToArray } from "utils/leaflet-utils";

const Line = hooksObserver(({ leaflet: { map } }) => {
  const {
    baselineStore: {
      status,
      positions,
      setPositions,
      setInitialPoints,
      extendedLine
    }
  } = useContext(StoresContext);

  const isEditing = status === 1;

  useEffect(() => {
    if (positions.length !== 2) {
      setInitialPoints(map.getCenter());
    }
  }, [positions]);

  const onMarkerDrag = event => {
    const index = event.target.options.options.index;
    const latLng = latLngToArray(event.target.getLatLng());

    const newPositions = [...positions];
    newPositions[index] = latLng;

    setPositions(newPositions);
  };

  return (
    <React.Fragment>
      {status && extendedLine && (
        <Polyline
          positions={[extendedLine.point1, extendedLine.point2]}
          weight={2}
          color={"red"}
        >
          <Tooltip
            key={`${extendedLine.point1.toString()}-${extendedLine.point2}`}
            direction="left"
            offset={[0, 0]}
            opacity={1}
            permanent
            style={{ opacity: 0.3 }}
          >
            basislijn
          </Tooltip>
        </Polyline>
      )}

      {isEditing &&
        positions &&
        positions.map((position, index) => (
          <Marker
            key={index}
            position={position}
            icon={draggableWaypointIcon}
            draggable
            onPreviewDrag
            onDrag={onMarkerDrag}
            zIndexOffset={50}
            options={{ index }} // index is used to update the position index
          />
        ))}
    </React.Fragment>
  );
});

export default withLeaflet(Line);
