import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { withStyles, Toolbar } from "@material-ui/core";

import styles from "./Step1Styles";
import { observer, inject } from "mobx-react";
import { FormattedMessage,injectIntl } from "react-intl";
import StepperVertical from "../partials/stepper/StepperVertical";

@inject("applicationStore")
@observer
class Step2 extends React.Component {
 
  render() {
      const { classes } = this.props;
    
    return (      
          <>
            <Toolbar>
              {" "}
              <Typography variant="subtitle2" gutterBottom>Stap 2: Basislijn</Typography>
            </Toolbar>
            <Divider />

        <div className={classes.content}>
            <StepperVertical/>
        </div>
        </>
    );
  }
}

export default injectIntl(withStyles(styles)(Step2));
