import { observable, action } from "mobx";
import { login, logout } from "../services/auth";
import { persist } from "mobx-persist";

export default class AuthStore {
  @persist
  @observable
  isLoggedIn = false;

  /**
   * returns logged in status
   */
  @action
  doLogin = async (email, password) => {
    this.isLoggedIn = await login(email, password);

    return this.isLoggedIn;
  };

  /**
   * returns logged in status
   */
  @action
  doLogout = async () => {
    this.isLoggedIn = !(await logout());

    return this.isLoggedIn;
  };
}

