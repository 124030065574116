import React, { Component } from "react";
import { TileLayer, WMSTileLayer as WMTSTileLayerOrg } from "react-leaflet";
import { inject, observer } from "mobx-react";
import WMTSTileLayer from "react-leaflet-wmts";
import { MAP_TYPES, Z_INDEXES } from "constants.js";
import { toJS } from "mobx";

@inject("mapStore", "uiStore")
@observer
class PublicLayersMap extends React.Component {
  render() {
    const {
      mapStore: {
        publicMaps: { activeArr }
      }
    } = this.props;

    return (
      <>
        {activeArr.map(layer => {
          return (
            (layer.type === MAP_TYPES.WMTS && (
              <WMTSTileLayer
                key={layer.name}
                zIndex={Z_INDEXES.MAP_PUBLIC_WMTS_TILE_LAYER}
                {...layer}
              />
            )) ||
            (layer.type === MAP_TYPES.TILE && (
              <TileLayer
                key={layer.name}
                zIndex={Z_INDEXES.MAP_PUBLIC_WMTS_TILE_LAYER}
                {...layer}
              />
            )) ||
            (layer.type === MAP_TYPES.WMS && (
              <WMTSTileLayerOrg
                key={layer.name}
                zIndex={Z_INDEXES.MAP_PUBLIC_WMTS_TILE_LAYER_ORG}
                {...layer}
              />
            ))
          );
        })}
      </>
    );
  }
}
export default PublicLayersMap;
