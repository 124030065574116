const styles = theme => ({
  card: {
    maxWidth: 345,
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
  },
  white: {
    color: "#FFF"
  }
});

export default styles;