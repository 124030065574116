import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import { mapList } from "config/config";

/**
 * model used to control the layer of the maps
 * maplist is a json with al possible layers
 * when changing the activemapindex the map will be rerendered
 *
 */
class MapLayerModel {
  @persist("list")
  @observable
  maps = mapList;

  @persist
  @observable
  activeMapIndex = 2;

  @action
  setActiveMapIndex = index => {
    this.activeMapIndex = index;
  };

  @computed
  get activeMap() {
    return this.maps[this.activeMapIndex];
  }
}

export default MapLayerModel;
