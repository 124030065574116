const styles = theme => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    grow: {
      flexGrow: 1
    },
    button: {
     margin: theme.spacing.unit,
      marginRight: "-14px"
  }
  });
  
  export default styles;