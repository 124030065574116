import React from "react";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";

import OpenWith from "@material-ui/icons/OpenWith";
import Adjust from "@material-ui/icons/Adjust";

let style = { width: 32, height: 32 };

export const draggableWaypointIcon = L.divIcon({
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  className: "",
  html: ReactDOMServer.renderToString(<OpenWith style={style} />)
});

export const zeroPointIcon = L.divIcon({
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  className: "",
  html: ReactDOMServer.renderToString(<Adjust style={style} />)
});
