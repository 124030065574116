import React, { Component } from "react";

import { Map, Marker } from "react-leaflet";
import { inject, observer } from "mobx-react";

import "leaflet/dist/leaflet.css";
import { MAP_CONFIG } from "../../../constants";
import LayerControl from "./controlButtons/LayerControl";
import GPSControl from "./controlButtons/GPSControl";

import myLocationSvg from "./icons/my-location.svg";
import AddSketchControl from "./controlButtons/AddSketchControl";
import ZoomControl from "./controlButtons/ZoomControl";
import { withStyles } from "@material-ui/core";
import styles from "./OrbitMapStyles";

import { SIDEBAR_CONFIG } from "constants.js";

import Logo from "./img/OrbitLogo.png";

import BaseLayersMap from "./layers/BaseLayersMap";
import PublicLayersMap from "./layers/PublicLayersMap";
import NeighborhoodMap from "./layers/NeighboorhoodMap";

var myLocationIcon = L.icon({
  iconUrl: myLocationSvg,
  iconSize: [38, 95]
});
@inject("uiStore", "mapStore")
@observer
class OrbitMap extends Component {
  leafletMap = React.createRef();

  componentDidUpdate() {
    this.leafletMap.current.leafletElement.invalidateSize();
  }

  centerMap = latlng => {
    this.props.mapStore.setLatLng(latlng);
    this.leafletMap.current.leafletElement.panTo(latlng); //force map to move to new center (if center before)
  };
  componentDidMount() {
    this.props.mapStore.setZoomLevel(
      this.leafletMap.current.leafletElement._zoom
    );
    this.leafletMap.current.leafletElement.attributionControl.options = {
      prefix: ""
    };
  }

  zoom = type => {
    if (type === "zoomIn") this.leafletMap.current.leafletElement.zoomIn();
    else this.leafletMap.current.leafletElement.zoomOut();
  };

  zoomEnd = () => {
    this.props.mapStore.setZoomLevel(
      this.leafletMap.current.leafletElement._zoom
    );
  };

  render() {
    const {
      classes,
      uiStore: { sideBarOpen, addSketchButton },
      mapStore: {
        latLng,
        mapLayer: { activeMap },
        zoomLevel
      }
    } = this.props;

    return (
      <Map
        ref={this.leafletMap}
        center={latLng}
        zoom={zoomLevel}
        onZoomend={this.zoomEnd}
        zoomControl={false}
        style={{
          height: "calc(100vh - 64px)",
          width: `calc(100vw -${sideBarOpen ? SIDEBAR_CONFIG.width : 0}px`
        }}
      >
        <BaseLayersMap />
        <PublicLayersMap />
        <NeighborhoodMap />

        {MAP_CONFIG.gpsButton ? (
          <>
            <GPSControl centerMap={this.centerMap} />
            <Marker
              key={Math.random()}
              position={[latLng.lat, latLng.lng]}
              icon={myLocationIcon}
            />
          </>
        ) : (
          <></>
        )}
        <LayerControl />
        {addSketchButton ? <AddSketchControl /> : <></>}
        <ZoomControl zoom={this.zoom} />
        <LayerControl />
        {this.props.children}
        <Marker
          key={Math.random()}
          position={[latLng.lat, latLng.lng]}
          icon={myLocationIcon}
        />
        <a href="https://www.orbitgis.com/" target="_blank">
          <img src={Logo} alt="Logo Orbit" className={classes.orbitLogo} />
        </a>
      </Map>
    );
  }
}

export default withStyles(styles)(OrbitMap);
