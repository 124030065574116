import React from "react";

import { inject, observer } from "mobx-react";
import { TileLayer } from "react-leaflet";
import WMTSTileLayer from "react-leaflet-wmts";
import { injectIntl } from "react-intl";
import { MAP_TYPES } from "constants.js";

@inject("mapStore", "uiStore")
@injectIntl
@observer
class BaseLayersMap extends React.Component {
  render() {
    const {
      mapStore: {
        mapLayer: { activeMap }
      }
    } = this.props;
    return (
      <>
        {" "}
        {activeMap.type === MAP_TYPES.WMTS && <WMTSTileLayer {...activeMap} />}
        {activeMap.type === MAP_TYPES.TILE && <TileLayer {...activeMap} />}
      </>
    );
  }
}
export default BaseLayersMap;
