import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import Stepper from "./model/Stepper";

export default class UiStore {
  @persist
  @observable
  muncipality = "Lokeren";

  @persist
  @observable
  sideBarOpen = true;

  @persist
  @observable
  sideBarRightOpen = false;

  @action
  setSideBarOpen = isOpen => {
    this.sideBarOpen = isOpen;
  };

  @persist
  @observable
  addSketchButton = true;

  @persist("object", Stepper)
  @observable
  stepper = new Stepper();

  @action
  setButtonAddSketch = visible => {
    this.addSketchButton = visible;
  };

  @persist
  @observable
  sideBarAddSketch = false;

  @action
  setSideBarAddSketch = visible => {
    this.sideBarAddSketch = visible;
  };

  @action
  setSideBarRightOpen = isOpen => {
    console.log(isOpen);
    this.sideBarRightOpen = isOpen;
  };
}
