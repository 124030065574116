import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import classNames from "classnames";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import { StoresContext } from "../../../contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { getStreetSuggestions } from "../../../services/geo";
import styles from "./HeaderStyles";

import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Icon,
  Chip,
  Avatar
} from "@material-ui/core";

import { HEADER_CONFIG } from "../../../constants";
import { injectIntl } from "react-intl";
import messages from "./messages";
require("layout/orbit.css");

const Header = hooksObserver(({ classes, intl, width }) => {
  const {
    uiStore: { sideBarOpen, setSideBarOpen, muncipality },
    mapStore: { searchLatLongForLocation }
  } = useContext(StoresContext);

  const [entries, setEntries] = useState([]);

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: sideBarOpen
      })}
    >
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <Icon className={"icon-logo-orbit-black"} />
        </IconButton>

        {isWidthUp("md", width) && (
          <Typography
            className={classes.title}
            variant="h6"
            color="inherit"
            noWrap
          >
            {intl.formatMessage(messages.title)}
          </Typography>
        )}
        <div className={classes.grow} />
        {HEADER_CONFIG.SEARCH_ENABLED && (
          <div className={classes.search}>
            <AutoComplete
              placeholder={intl.formatMessage(messages.placeholder, {
                muncipality
              })}
              noResult={intl.formatMessage(messages.placehnoResultolder)}
              entries={entries}
              onChange={async value => searchLatLongForLocation(value)}
              apiCall={async value => {
                const result = await getStreetSuggestions({
                  value,
                  count: HEADER_CONFIG.SEARCH_RESULT_COUNT,
                  muncipality
                });
                setEntries(result);
              }}
            />
          </div>
        )}
        <Chip
          color="secondary"
          avatar={
            <Avatar
              alt="John Doe"
              src="https://www.politieantwerpen.be/sites/default/files/jo_grijs.jpg"
            />
          }
          //avatar={<Avatar>J</Avatar>}
          label="John Doe"
          className={classes.chip}
        />
        <div className={classes.grow} />
        {/*action button*/}
      </Toolbar>
    </AppBar>
  );
});

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withWidth()(injectIntl(withStyles(styles)(Header)));
