import { SIDEBAR_CONFIG } from "constants.js";

const styles = theme => ({
  root: {
    width: SIDEBAR_CONFIG.width
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  badge: {
    marginLeft: "16px"
  },
  pos: {
    marginBottom: 12
  },
  postop: {
    marginTop: 12,
    marginBottom: 12
  }
});

export default styles;
