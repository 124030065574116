import { defineMessages } from "react-intl";

export default defineMessages({
  neighborhood: {
    id: "neighborhood.title",
    defaultMessage: "Zonale kaarten"
  },
  zone: {
    id: "zone.title",
    defaultMessage: "Zonale kaarten"
  },
  zoneTowingService: {
    id: "zone.towingservice",
    defaultMessage: "Takeldienst"
  },
  zoneNeighborHoodService: {
    id: "zone.zoneneighborhood",
    defaultMessage: "Wijken"
  },
  zoneSubdivisionsService: {
    id: "zone.zonesubdivisions",
    defaultMessage: "(Deel)gemeenten"
  },
  zoneNuisanceService: {
    id: "zone.nuisance",
    defaultMessage: "overlast"
  },
  zoneSchoolEnvironment: {
    id: "zone.schoolenvironment",
    defaultMessage: "School omgeving"
  },
  zonepedestrianArea: {
    id: "zone.pedestrianArea",
    defaultMessage: "Voetgangersgebied"
  },
  zoneCrime: {
    id: "zone.crime",
    defaultMessage: "Misdaad"
  },
  zoneAccidents: {
    id: "zone.acidents",
    defaultMessage: "Ongelukken"
  },
  public: {
    id: "public.title",
    defaultMessage: "Publieke kaarten"
  },
  publicPRECIPITATION: {
    id: "public.Precipitation",
    defaultMessage: "Neerslag"
  },
  publicGIPOD_MAN: {
    id: "public.GIPOD_MAN",
    defaultMessage: "Manifestaties vandaag"
  },
  publicGIPOD_WO: {
    id: "public.GIPOD_WO",
    defaultMessage: "Werkopdrachten"
  },
  transparantie: {
    id: "public.transparantie",
    defaultMessage: "Transparantie"
  },
  zoomerror: {
    id: "public.zoomerror",
    defaultMessage: "Zoom in om de kaart te zien"
  }
});
