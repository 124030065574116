const styles = theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
    },
    navigateButton: {
        color: "white"
    }
  }); 
  
  export default styles;