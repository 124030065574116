
const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit*2,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    }
})

export default styles;
