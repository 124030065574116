const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: "20px",
    marginBottom: "0px"

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: "0px",
    width: 100,
  },
  circle: {
    position: "absolute",
    width: "20px",
    height: "20px",
    '&:circle': {
      stroke: "green"
    }
  }
 
})
  
  export default styles;