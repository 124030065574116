import React from "react";
import { observer, inject } from "mobx-react";
import { withStyles} from "@material-ui/core";

import styles from "./CreateSketchStyles";
import Step1 from "./Step1";
import Step2 from "./Step2";

@inject("uiStore")
@observer
class CreateSketch extends React.Component {
  render() {
    const { classes, uiStore: { stepper } } = this.props;

    return (
      <div className={classes.root}>
        {stepper.currentStep === 0 && (<Step1 />)}
        {stepper.currentStep === 1 && (<Step2/>)}
      </div>
    );
  }
}

export default withStyles(styles)(CreateSketch);