import React from "react";
import classNames from "classnames";
import {  withStyles, Grid , TextField, InputLabel, MenuItem, FormControl, Select, Button } from "@material-ui/core";
import styles from "./EditPartyStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import { observer } from 'mobx-react'
import messages from './messages'
import { injectIntl } from "react-intl";
import Vehicle from "../vehicle/Vehicle";

@injectIntl
@observer
class EditParty extends React.Component {
  handleChange = (type, value) => {
  this.props.party.update(type,value)
  };
  svg = [
    {
      name: "police",
      path: "car_1.svg"
    },
    {
      name: "medical",
      path: "car_6.svg"
    },
    {
      name: "sport",
      path: "car_3.svg"
    }
  ];
  undo() {
    console.log('undo')
  }

  render() {
    const textFields = ['firstName', 'lastName', 'licensePlate'];
    const { classes, party, intl, remove} = this.props;

    let car = this.svg.find(x => x.name === party.carType);
    if (car && car.path) car = car.path;

    return (
      <div className={classes.root}>
        {textFields.map((id, index) => (
          <TextField
            key={index}
            id="standard-dense"
            label={intl.formatMessage(messages[id])}
            value={party[id]}
            className={classNames(classes.textField, 
              index===0 ? classes.firstDense: classes.dense
            )}
            onChange={(e) => { this.handleChange(id, e.target.value) }}
            margin="dense"
          />

        ))}

        <FormControl className={classNames(classes.textField, classes.dense)}>
          <InputLabel htmlFor="carType">{intl.formatMessage(messages['carType'])}</InputLabel>
          <Select
            value={party.carType}
            onChange={(e) => { this.handleChange("carType", e.target.value) }}
            inputProps={{
              id: "carType"
            }}
          >
            <MenuItem value={"police"}>Politiewagen</MenuItem>
            <MenuItem value={"medical"}>Ambulance</MenuItem>
            <MenuItem value={"sport"}>Sportwagen</MenuItem>
          </Select>
        </FormControl>
        {party.carType && (<Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className={classes.imageGrid}
        >     
          <Grid item>
            <div onClick={()=>{this.undo()}}></div>
            <Vehicle
              height="100px"
              img={"./static/images/" + car}
              addMarker={party.addCarMarker}
              markers={party.carMarkers}
              activeMarkerId={party.activeMarkerId}
              setActiveMarkerId={party.setActiveMarkerId}
            />
          </Grid>
        </Grid>)}
        <div  className={classes.center}>
        <Button variant="contained" className={classes.button} onClick={(e) => remove(party.id)}>
            <DeleteIcon className={classes.deleteIcon} />
          </Button>
          </div>
      </div>
    );
  }
}

export default withStyles(styles)(EditParty);