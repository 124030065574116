import React, { useContext, useEffect, memo } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import { Polyline, Marker, Tooltip, withLeaflet } from "react-leaflet";

import { StoresContext } from "contexts";
import { zeroPointIcon } from "./icon";
import { toJS } from "mobx";
import { calulateDistance, calculatePerpendicular } from "utils/calculate";

const ZeroLine = hooksObserver(({ leaflet: { map } }) => {
  const {
    baselineStore: {
      status,
      extendedLine,
      startPerpendicular,
      endPerpendicular,
      setPreviewPerpendicular,
      zeroPoint,
      setZeroPoint,
      clearPreviewPerpendicular
    }
  } = useContext(StoresContext);

  const onMapClick = event => (status === 2 ? setZeroPoint() : null);
  const onMapMouseOut = event => clearPreviewPerpendicular();

  const onMapMouseMove = event => {
    if (extendedLine) {
      const { latlng } = event;
      const { start, end } = calculatePerpendicular({
        line: extendedLine,
        position: latlng
      });

      setPreviewPerpendicular(start, end);
    }
  };

  useEffect(() => {
    map.on("click", onMapClick);
    map.on("mousemove", onMapMouseMove);
    map.on("mouseout", onMapMouseOut);

    return () => {
      map.off("click", onMapClick);
      map.off("mousemove", onMapMouseMove);
    };
  }, [status]);

  const points = {
    fixedPoint: startPerpendicular,
    zeroPoint: endPerpendicular
  };

  return (
    <React.Fragment>
      {zeroPoint && drawZeroLine(zeroPoint, extendedLine)}
      {status === 2 && drawPreviewZeroLine(points)}
    </React.Fragment>
  );
});

export default withLeaflet(ZeroLine);

const drawZeroLine = (zeroPoint, extendedLine) => {
  const line = toJS(extendedLine);

  const { start, end } = calculatePerpendicular({
    line,
    position: zeroPoint
  });

  if (!start && !end) {
    return null;
  }

  return (
    <DrawLineWithMarkers id={"drawZeroLine"} point1={start} point2={end} />
  );
};

const drawPreviewZeroLine = points => {
  const { fixedPoint, zeroPoint } = points;
  if (!fixedPoint && !zeroPoint) {
    return null;
  }
  return (
    <DrawLineWithMarkers
      id={"drawPreviewZeroLine"}
      point1={fixedPoint}
      point2={zeroPoint}
    />
  );
};

const DrawLineWithMarkers = memo(
  ({
    id,
    point1,
    point2,
    zeroPoint,
    options = { dashArray: "5,5", color: "red" }
  }) => {
    const perpendicularDistance =
      Math.round(calulateDistance(point1, point2) * 100) / 100;

    const zeroDistance = zeroPoint
      ? Math.round(calulateDistance(point2, zeroPoint) * 100) / 100
      : null;

    const distance = zeroDistance
      ? `${zeroDistance} m. - ${perpendicularDistance} m.`
      : `${perpendicularDistance} m.`;
    return (
      <React.Fragment key={Math.random()}>
        <Marker position={point1} icon={zeroPointIcon} zIndexOffset={50} />
        <Marker position={point2} icon={zeroPointIcon} zIndexOffset={50} />
        <Polyline positions={[point2, point1]} {...options}>
          <Tooltip
            key={`${point1.toString()}-${point2.toString()}`}
            direction="right"
            offset={[0, 20]}
            opacity={1}
            permanent
            style={{ opacity: 0.3 }}
          >
            {distance}
          </Tooltip>
        </Polyline>
      </React.Fragment>
    );
  },
  areEqual
);

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  if (prevProps.point1.toString() === nextProps.point1.toString()) {
    return true;
  }
  return false;
}
