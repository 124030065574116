const styles = theme => ({
  card: {
    minWidth: 275
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  action: {
    flexDirection: "row-reverse"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "25px",
    marginBottom: "25px",
    alignItems: "center",
    justifyContent: "center"
  },
  textField: {
    width: "100%"
  },
  formControl: {
    width: "100%",
    margin: "10px 0px"
  },
  loginHeader: {
    fontSize: "34px",
    fontWeight: "bold",
    padding: "50px 0",
    textAlign: "center",
    margin: "-20px"
  },
  loginHead: {
    display: "block",
    borderRadius: "50%",
    color: "#FFF",
    width: "80px",
    height: "80px",
    fontSize: "50px",
    margin: "0 auto",
    border: "6px solid #ddd"
  },
  loginButton: {
    "&:hover": {
      backgroundColor: "#299bf1"
    }
  },
  inputLabelFocused: {},
  inputInkbar: {
    "&:after": {}
  },
  inputInkbarError: {
    "&:after": {}
  },
  avatar: {
    height: "30px",
    width: "30px"
  }
});

export default styles;
