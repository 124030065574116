import React from "react";

import { inject, observer } from "mobx-react";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";

import styles from "./LayerStyles";
import { injectIntl } from "react-intl";

@inject("mapStore", "uiStore")
@injectIntl
@observer
class BaseLayers extends React.Component {
  selectLayer(index) {
    const {
      mapStore: {
        mapLayer: { setActiveMapIndex }
      },
      uiStore: { setSideBarRightOpen }
    } = this.props;
    setActiveMapIndex(index);
    setSideBarRightOpen(false);
  }

  render() {
    const {
      classes,
      mapStore: {
        mapLayer: { maps }
      }
    } = this.props;
    return (
      <List className={classes.root}>
        {maps.length > 0 &&
          maps.map((layer, index) => (
            <ListItem
              button
              key={index}
              onClick={event => this.selectLayer(index)}
            >
              <ListItemAvatar>
                <Avatar
                  alt="GRB"
                  src={layer.avatar}
                  className={classes.avatar}
                />
              </ListItemAvatar>
              <ListItemText primary={layer.title} />
            </ListItem>
          ))}
      </List>
    );
  }
}
export default withStyles(styles)(BaseLayers);
