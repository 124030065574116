import * as turf from "@turf/turf";

export const calculatePerpendicular = ({ line, position }) => {
  const { point1, point2 } = line;
  let reversedLine = turf.lineString([
    [...point1].reverse(),
    [...point2].reverse()
  ]);
  let pt = turf.point([position.lng, position.lat]);
  let snapped = turf.nearestPointOnLine(reversedLine, pt);

  const start = pt.geometry.coordinates.reverse();
  const end = snapped.geometry.coordinates.reverse();

  return { start, end };
};

export const calulateDistance = (from, to, options = { units: "meters" }) => {
  const fromTurf = turf.point(from);
  const toTurf = turf.point(to);

  const distance = turf.distance(fromTurf, toTurf, options);

  return distance;
};
