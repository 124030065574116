import React, { useContext } from "react";
import Control from "react-leaflet-control";
import { observer as hooksObserver } from "mobx-react-lite";

import ZoomInIcon from "@material-ui/icons/Add";
import ZoomOutIcon from "@material-ui/icons/Remove";

import Fab from "@material-ui/core/Fab";

import { withStyles } from "@material-ui/core/styles";

import styles from "./ZoomControlStyles";
import { StoresContext } from "contexts";

const ZoomControl = hooksObserver(({ classes, zoom }) => {
  const {
    mapStore: {
      zoomLevel,
      mapLayer: { activeMap }
    }
  } = useContext(StoresContext);

  return (
    <Control position="topright">
      <div>
        <Fab
          disabled={zoomLevel === activeMap.maxZoom}
          color="secondary"
          size="small"
          className={classes.fab}
          onClick={() => zoom("zoomIn")}
        >
          <ZoomInIcon />
        </Fab>{" "}
      </div>
      <Fab
        color="secondary"
        disabled={zoomLevel === activeMap.minZoom}
        size="small"
        className={classes.fab}
        onClick={() => zoom("zoomOut")}
      >
        <ZoomOutIcon />
      </Fab>
    </Control>
  );
});

export default withStyles(styles)(ZoomControl);
