/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  placeholder: {
    id: "header.search.placeholder",
    defaultMessage: "Zoek {muncipality}..."
  },
  placehnoResultolder: {
    id: "header.search.noResult",
    defaultMessage: "Geen resultaat gevonden..."
  },
  title: {
    id: "header.logo.title",
    defaultMessage: "Webschetsen"
  }
});
