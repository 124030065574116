import { fade } from "@material-ui/core/styles/colorManipulator";
import { Z_INDEXES } from "constants.js";

const styles = theme => ({
  map: {
    width: "100%",
    height: "100%"
  },
  orbitLogo: {
    position: "absolute",
    bottom: "30px",
    right: "20px",
    //   height: "250px",
    opacity: 0.8,
    zIndex: Z_INDEXES.MAP_ORBIT_LOGO
  }
});

export default styles;
