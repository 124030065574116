import red from '@material-ui/core/colors/red';

const styles = theme => ({
    root: {
        width: "100%",
        height: "100%"
    },
    textField: {
        width: "100%",
    },
    dense: {
        marginTop: 19,
    },
    firstDense: {
        marginTop: 0
    },
    imageGrid: {
        marginTop: "50px",
    },
    center: {
        textAlign: 'center'
    },
    button: {
        width: "100%",
        marginTop: "45px",
        marginBottom: "0px",
    },
    
  deleteIcon: {
      color:red[700]
    }

});

export default styles;