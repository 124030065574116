import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";

import { MenuItem, Paper, InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import styles from "./AutoCompleteStyles";

class AutoComplete extends React.Component {
  render() {
    const { classes, entries, onChange, placeholder, noResult } = this.props;

    const noResultItem = { label: noResult, value: noResult };
    return (
      <div>
        <Downshift
          onChange={item => onChange(item.value)}
          itemToString={item => (item ? item.value : "")}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            selectedItem,
            reset
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                placeholder,
                InputProps: getInputProps({
                  onChange: e => {
                    const searchValue = e.target.value;
                    reset();
                    if (this.props.apiCall) {
                      this.props.apiCall(searchValue);
                    }
                  }
                })
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {entries.length > 0
                      ? entries.map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem
                          })
                        )
                      : renderNoResult({
                          suggestion: noResultItem,
                          index: 0,
                          highlightedIndex,
                          selectedItem
                        })}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}
AutoComplete.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  noResult: PropTypes.string,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }).isRequired
  )
};

AutoComplete.defaultProps = {
  onChange: () => console.log("no onChange handler given to the autoComplete"),
  placeholder: "Zoek op straatnaam...",
  noResult: "Geen resultaten gevonden...",
  entries: []
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, placeholder, ...other } = inputProps;
  return (
    <>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        {...{
          inputRef: ref,
          ...InputProps
        }}
        {...other}
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
      />
    </>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem
    ? (selectedItem.label || "").indexOf(suggestion.label) > -1
    : false;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

function renderNoResult({ suggestion, index, selectedItem }) {
  return (
    <MenuItem
      key={suggestion.label}
      component="div"
      style={{
        fontWeight: 400
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

export default withStyles(styles)(AutoComplete);
