import React from "react";
import { observer, inject } from "mobx-react";
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {
  withStyles,
  Button
} from "@material-ui/core";

import styles from "./StepperHorizontalStyles";

@inject("uiStore")
@observer
class StepperHorizontal extends React.Component {
 
  render() {
    const { classes, uiStore:{stepper}} = this.props;
    return (
        <MobileStepper
        variant="progress"
        steps={stepper.maxSteps+1}
        position="static"
        activeStep={stepper.currentStep}
        className={classes.root}
        nextButton={
          <Button size="small"  className={classes.navigateButton} onClick={stepper.nextStep} disabled={stepper.lastStep}>
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" className={classes.navigateButton} onClick={stepper.prevStep} disabled={stepper.firstStep}>
            <KeyboardArrowLeft />            
          </Button>
        }
      />
    );
  }
}

export default withStyles(styles)(StepperHorizontal);
