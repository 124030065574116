import React, { useContext } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import OpenWith from "@material-ui/icons/OpenWith";

import { withStyles, Button } from "@material-ui/core";

import styles from "./StepperVerticalStyles";
import { StoresContext } from "contexts";

import Restore from "@material-ui/icons/Restore";

function getSteps() {
  return [
    "Zoek uw lokatie",
    "Positioneer uw referentielijn",
    "Plaats een referentiepunt",
    "Laad meetpunten voertuigen",
    "Optimalizeer labels"
  ];
}

function getStepContent({ step, setPositions, zeroPoint, clearZeroPoint }) {
  switch (step) {
    case 0:
      return (
        <React.Fragment>
          Navigeer op de map naar de plaats waar het ongeluk gebeurd is.
        </React.Fragment>
      );
    case 1:
      return (
        <React.Fragment>
          Versleep beide markers( <OpenWith />) tot wanneer uw basislijn correct
          staat
          <Button color="inherit" onClick={() => setPositions([])}>
            <Restore />
            reset/centreer lijn
          </Button>
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          Klik de plaats aan waar uw referentiepunt moet komen
          <Button
            disabled={!zeroPoint}
            color="inherit"
            onClick={() => clearZeroPoint([])}
          >
            <Restore />
            Verwijder referentiepunt
          </Button>
        </React.Fragment>
      );
    case 3:
      return "An ad group contains one or more ads which target a shared set of keywords.";
    default:
  }
}

const CreateSketch = hooksObserver(({ classes }) => {
  const {
    baselineStore: {
      status,
      setStatus,
      setPositions,
      zeroPoint,
      clearZeroPoint
    }
  } = useContext(StoresContext);

  const handleNext = () => {
    setStatus(status + 1);
  };

  const handleBack = () => {
    setStatus(status - 1);
  };

  const steps = getSteps();

  return (
    <>
      <Stepper activeStep={status} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>
                {getStepContent({
                  step: index,
                  setPositions,
                  zeroPoint,
                  clearZeroPoint
                })}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={status === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Vorige
                  </Button>
                  {status !== steps.length - 1 && (
                    <Button
                      disabled={status === 2 && !zeroPoint}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {status === steps.length - 1 ? "Finish" : "Volgende"}
                    </Button>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
});

export default withStyles(styles)(CreateSketch);
