import { MAP_TYPES, MAP_TILE_MATRIX, MAP_TILE_FORMAT } from "constants.js";
import * as publicLayers from "./publicLayers";
import * as neighboorhoodLayers from "./neighboorhoodLayers";

const mapList = [
  {
    avatar: "https://omgeving.geoportaal.be/static/img/grb.png",
    url: "https://tile.informatievlaanderen.be/ws/raadpleegdiensten/wmts",
    layer: "grb_bsk",
    tilematrixSet: MAP_TILE_MATRIX.GOOGLE,
    format: MAP_TILE_FORMAT.PNG,
    maxZoom: 19,
    minZoom: 1,
    enablePrefixTileMatrix: false,
    title: "GRB",
    type: MAP_TYPES.WMTS,
    attribution:
      '<a target="_blank" href="https://overheid.vlaanderen.be/GRB-GRB-raadpleegdiensten">Grootschalig Referentie Bestand Vlaanderen, Informatie Vlaanderen</a>'
  },
  {
    avatar: "https://omgeving.geoportaal.be/static/img/grb_grijs.png",
    url: "http://geoservices-urbis.irisnet.be/geowebcache/service/wmts/",
    layer: "urbisNL",
    tilematrixSet: MAP_TILE_MATRIX.GOOGLE_OLD,
    enablePrefixTileMatrix: true,
    format: MAP_TILE_FORMAT.PNG,
    title: "URBIS",
    type: MAP_TYPES.WMTS,
    attribution:
      '<a target="_blank" href="https://overheid.vlaanderen.be/GRB-GRB-raadpleegdiensten">Grootschalig Referentie Bestand Vlaanderen, Informatie Vlaanderen</a>',
    maxZoom: 19,
    minZoom: 1
  },
  {
    avatar: "https://omgeving.geoportaal.be/static/img/osm.png",
    attribution:
      '© <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>-auteurs',
    maxZoom: 19,
    minZoom: 10,
    type: "tile",
    url: "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
    title: "OpenStreetMap"
  }
];

const baseLocation = {
  lat: 50.85114,
  lng: 2.88569
};

export { baseLocation, mapList, publicLayers, neighboorhoodLayers };
export default mapList;
