import { SIDEBAR_CONFIG } from "constants.js";

const styles = theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "row"
  },
  content: {
    flexGrow: 1,

    marginLeft: -SIDEBAR_CONFIG.width
  },
  contentShift: {
    marginLeft: 0
  },
  drawerHeader: {
    backgroundColor: "FF0000",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  addSketch: {
    position: "absolute",
    right: "18px",
    bottom: "18px",
    zIndex: "999"
  }
});

export default styles;
