import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import unique from 'uuid'
import Position from "./Position"

export default class CarMarker {
    @persist
    @observable
    type;

    @persist
    @observable
    id = unique();

    @persist('object', Position)
    @observable
    referer = new Position()

    @persist('object', Position)
    @observable
    position = new Position()

    constructor(marker) {
        if (marker) {
            this.referer = new Position(marker)
            this.type = marker.type
        }       
    }

    @action
    setPosition = (position) => {
        this.position = new Position(position)
    }

    @computed
    get validPosition() {
        return this.position.x && this.position.y
    }
        
}