import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { observer, inject } from "mobx-react";

import styles from "./BasePageStyles";
import OrbitMap from "../partials/map/OrbitMap";
import Header from "../partials/header/Header";
import Sidebar from "../partials/sidebar/Sidebar";
import AddSketch from "../addSketch/AddSketch";
import GPSControl from "containers/partials/map/controlButtons/GPSControl";

import Baseline from "containers/partials/map/baseline/Baseline";

@inject("uiStore", "mapStore")
@observer
class BasePage extends React.Component {
  state = {
    mobileOpen: false
  };
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const {
      classes,
      uiStore,
      mapStore: {
        mapLayer: { maps }
      }
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <Sidebar />
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: uiStore.sideBarOpen
          })}
        >
          <div className={classes.drawerHeader} />
          {uiStore.sideBarAddSketch ? (
            <AddSketch injectClass={classes.addSketch} />
          ) : (
            <></>
          )}

          {maps.length > 0 && (
            <OrbitMap>
              <GPSControl />
              <Baseline />
            </OrbitMap>
          )}
        </main>
      </div>
    );
  }
}

BasePage.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BasePage);
