import React from "react";
import Control from "react-leaflet-control";


import Fab from "@material-ui/core/Fab";
import Addicon from "@material-ui/icons/NoteAdd";

import { withStyles } from "@material-ui/core/styles";
import styles from "./AddSketchControlStyles";
import { observer, inject } from "mobx-react";

@inject("uiStore")
@observer
class AddSketchControl extends React.Component {
  addSketch() {      
    this.props.uiStore.setButtonAddSketch(false);
    this.props.uiStore.setSideBarAddSketch(true);
  }

  render() {
    const { classes} = this.props;
    return (
      <div>
        <Control position="bottomright">
          <Fab color="secondary" size="medium" className={classes.fab} onClick={() => this.addSketch()}>
            <Addicon />
          </Fab>
        </Control>
      </div>
    );
  }
}
export default withStyles(styles)(AddSketchControl);
