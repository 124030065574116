import React, { Component, Fragment } from "react";
import { Redirect, Route } from "react-router";
import Login from "../containers/login/Login";
import OrbitMap from "../containers/partials/map/OrbitMap";
import { withErrorBoundary } from "../containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";

import PublicRoutes from "./PublicRoutes";
import { ROUTE_LOGIN, ROUTE_MAP, ROUTE_BASE } from "./RouteList";
import BasePage from "../containers/base/BasePage";

class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <PublicRoutes>
          <Route exact path={ROUTE_LOGIN} component={Login} />
          <Redirect to={ROUTE_LOGIN} />
        </PublicRoutes>
        <PrivateRoutes>
          <Route exact path={ROUTE_BASE} component={BasePage} />
          <Route exact path={ROUTE_MAP} component={OrbitMap} />
          <Redirect to={ROUTE_BASE} />
        </PrivateRoutes>
      </Fragment>
    );
  }
}

export default withErrorBoundary(AppRoute);
