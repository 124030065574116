import React from "react";
import {
  withStyles,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField
} from "@material-ui/core";
import { observer } from "mobx-react";
import styles from "./VehicleStyles";

@observer 
class Vehicle extends React.Component {

  root = React.createRef();

  state = {
    loaded: false,
    damageSelector: false,
  };

  toggleDamage = () => {
    this.props.setActiveMarkerId(null)
    this.setState(state => ({ damageSelector: !state.damageSelector}));
  };

  handleMarkerPostion = (marker, type, value) => {
    marker.position["set" + type](value);
  }

  handleClickImg = event => {
    if (this.state.damageSelector) {     
      let bounds = event.target.getBoundingClientRect();
      let x1 = event.clientX - bounds.left;
      let y1 = event.clientY - bounds.top;
      this.props.addMarker({
        x: x1 / bounds.width.toFixed(4),
        y: y1 / bounds.height.toFixed(4),
        type: 'damage'
      });
    }
  };

  handleClickMarker(marker) {
    if (!this.state.damageSelector) {
      this.props.setActiveMarkerId(marker.id)
    }
  }

  getIcon(marker) {
    switch (marker.type) {
      case "measure":
        let icon =  "./static/images/circle_main.svg"
        if (marker.id === this.props.activeMarkerId) icon = "./static/images/circle_main_filled.svg";
        else if(marker.validPosition) icon = "./static/images/circle_green_filled.svg";
        return icon;        
      case "damage":
        return "./static/images/cancel.svg";
      default:
        return "";
    }
  }

  getPoints(marker) {
    let bounds = this.root.current.getBoundingClientRect();
    return {
      top: marker.y * bounds.height,
      left: marker.x * bounds.width
    };
  }  

  setMeasureMarkers() {
    let positions = [
      {
        top:0,
        left:0
      },
      {
        top:0,
        left:1
      },
      {
        top:0.5,
        left:1
      },
      {
        top:1,
        left:1
      },
      {
        top:1,
        left:0
      },
      {
        top:0.5,
        left:0
      },
      {
        top:0.5,
        left:0.5
      }
    ]
    positions.forEach(position => {
      this.props.addMarker({
        x: position.left,
        y: position.top,
        type: "measure"
      });
    });
  }

  componentDidMount() {
    this.root.current.addEventListener("load", () => {
      if (!this.props.markers.filter((marker) => marker.type === "measure")[0]) {
        this.setMeasureMarkers();      
      }    
      this.setState(() => {
        return { loaded: true };
      });
    });
  }

  render() {
    const { height, img, markers, classes, activeMarkerId } = this.props;
    const { loaded, damageSelector } = this.state;

    const activeMarker = markers.filter((marker) => marker.id === activeMarkerId)[0];

    return (
      <>
        <div style={{ position: "relative" }}>
          <img
            height={height}
            src={img}
            alt="car"
            ref={this.root}
            onClick={e => this.handleClickImg(e)}
          />
          {loaded  &&
            markers.map((marker, index) => (
            <img
                key={index}
                src={this.getIcon(marker)}
                alt="marker"
                style={{
                  width: "10px",
                  height: "10px",
                  position: "absolute",
                  marginLeft: '-5px',
                  marginTop: "-5px",
                  top: this.getPoints(marker.referer).top,
                  left: this.getPoints(marker.referer).left
                }}
                onClick={e => this.handleClickMarker(marker)}
              />
            ))}
        </div>
        {activeMarker &&
          <form className={classes.container} noValidate autoComplete="off">
          <TextField
            type="number"
            id="standard-name"
            label="X"
            className={classes.textField}
            value={activeMarker.position.x == null ? '' :activeMarker.position.x }
            onChange={(e) => this.handleMarkerPostion(activeMarker, 'X', e.target.value)}
            margin="normal"
          />

          <TextField
            type="number"
            label="Y"
            className={classes.textField}
            value={activeMarker.position.y == null ? '' :activeMarker.position.y }
            onChange={(e) => this.handleMarkerPostion(activeMarker, 'Y', e.target.value)}
            margin="normal"
          />
        </form>
        }
        <FormGroup row style={{ marginTop: "45px"}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={damageSelector}
                onChange={e => this.toggleDamage()}
                value="damage"
              />
            }
            label="Schade punten plaatsen"
          />
        </FormGroup>
      </>
    );
  }
}

export default withStyles(styles)(Vehicle);