import { SIDEBAR_CONFIG } from "constants.js";

const styles = theme => ({
  root: {
    overflowY: "auto",
    width: SIDEBAR_CONFIG.width
  },
  fab: {
    margin: theme.spacing.unit,
    color: "#e6e6e6"
  },
  toolbar: {
    color: "white",
    backgroundColor: theme.palette.secondary.main
  },
  drawer: {
    overflowY: "initial"
  },
  content: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  }
});

export default styles;
