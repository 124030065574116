import React, { useContext } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import useMousetrap from "react-hook-mousetrap";

import { StoresContext } from "contexts";
import Line from "./Line";
import ZeroLine from "./ZeroLine";

const Baseline = hooksObserver(() => {
  const {
    baselineStore: { status }
  } = useContext(StoresContext);

  /* // useMousetrap(<Keys>, <Callback>)
  useMousetrap(
    "ctrl+z", // any mousetrap combo, or array of combo

    () => {
      console.log("ctrl + z");
      // triggered on key combo
      undo.undo();
    }
  ); */

  return (
    <React.Fragment>
      {status >= 1 && <Line />}
      {status >= 2 && <ZeroLine />}
    </React.Fragment>
  );
});

export default Baseline;
