import { Drawer, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { observer } from "mobx-react";
import styles from "./SidebarStyles";
import { inject } from "mobx-react";
import CreateSketch from "../../createSketch/CreateSketch";
import StepperHorizontal from "../stepper/StepperHorizontal";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";

function Sidebar({ classes, width, uiStore: { sideBarOpen, setSideBarOpen } }) {
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <StepperHorizontal />
      </div>
      <div className={classes.content}>
        <CreateSketch />
        <Typography variant="h6" className={classes.whiteText}>
          Header
        </Typography>
        <div className={classes.grow} />
        {isWidthDown("md", width) && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={e => {
              setSideBarOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withWidth()(
  inject("uiStore")(withStyles(styles)(observer(Sidebar)))
);
