import React from "react";
import { observer, inject } from "mobx-react";
import classNames from "classnames";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  IconButton
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import styles from "./AddSketchStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Gesture";
import MeasureIcon from "@material-ui/icons/LinearScale";
import GPSIcon from "@material-ui/icons/TapAndPlay";

@inject("uiStore")
@observer
class AddSketch extends React.Component {
  close = () => {
    this.props.uiStore.setButtonAddSketch(true);
    this.props.uiStore.setSideBarAddSketch(false);
  };
  selectType(type) {
    console.log(type)
  }
  render() {
    const { classes } = this.props;
    return (
      <Card className={classNames(classes.card, this.props.injectClass)}>
        <CardHeader
          title="Nieuwe schets"
          classes={{
            root: classes.header,
            title: classes.white
          }}
          action={
            <IconButton onClick={() => this.close()}>
              <ClearIcon className={classes.white} />{" "}
            </IconButton>
          }
        />{" "}
        <CardContent>
          <List component="nav">
            <ListItem button onClick={() => this.selectType("NO_MEASURE")}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="Zonder meeting" />
            </ListItem>{" "}
            <ListItem button onClick={() => this.selectType("MEASURE")}>
              <ListItemIcon>
                <MeasureIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="Met meeting" />
            </ListItem>{" "}
            <ListItem button onClick={() => this.selectType("GPS")}>
              <ListItemIcon>
                <GPSIcon />
              </ListItemIcon>{" "}
              <ListItemText primary="GPS meeting" />
            </ListItem>{" "}
          </List>{" "}
          <Divider />
        </CardContent>{" "}
      </Card>
    );
  }
}

export default withStyles(styles)(AddSketch);
