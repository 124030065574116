import { SIDEBAR_CONFIG } from "constants.js";

const styles = theme => ({
  drawer: {
    width: SIDEBAR_CONFIG.width,
    flexShrink: 0,
    borderRight: "0px"
  },
  drawerPaper: {
    width: SIDEBAR_CONFIG.width
  },
  drawerHeader: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    padding: "0 0px",
    ...theme.mixins.toolbar
  },
  whiteText: {
    color: "white"
  },
  content: {
    overflowY: "auto",
    height: "100%"
  }
});

export default styles;
