import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import MapLayerModel from "./models/MapLayerModel";
import PublicModel from "./models/PublicModel";
import { getLocation } from "../services/geo";
import NeighboorhoodModel from "./models/NeighboorhoodModel";

export default class MapStore {
  /**
   * model used to control the layer of the maps
   */
  @persist("object", MapLayerModel)
  @observable
  mapLayer = new MapLayerModel();

  @persist("object", PublicModel)
  @observable
  publicMaps = new PublicModel();

  @persist("object", NeighboorhoodModel)
  @observable
  neighboorhood = new NeighboorhoodModel();

  @persist("object")
  @observable
  latLng = {
    lat: 50.82803,
    lng: 3.26487
  };

  @observable
  bounds;

  @persist
  @observable
  zoomLevel = 19;

  @action
  setLatLng = latLng => {
    this.latLng = latLng;
  };

  @action.bound
  setBounds = (bounds, pad = 0.3) => {
    if (bounds) bounds = bounds.pad(pad);
    this.bounds = bounds;
  };

  @action
  setZoomLevel = zoomLevel => {
    this.zoomLevel = zoomLevel;
  };

  @action
  setLatLng = latLng => {
    this.latLng = latLng;
  };

  @action.bound
  async searchLatLongForLocation(location) {
    const result = await getLocation(location);
    this.latLng = result;
    this.setZoomLevel(this.mapLayer.activeMap.maxZoom);
  }

  @computed
  get location() {
    return {
      lat: this.lat,
      lng: this.lng
    };
  }
}
