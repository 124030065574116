import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";

export default class Stepper {
  @persist
  @observable
  maxSteps = 5;

  @persist
  @observable
  currentStep = 0;

  @action
  nextStep = () => {
    this.currentStep++;
  };

  @action
  prevStep = () => {
    this.currentStep--;
  };

  @action
  goToStep = step => {
    this.currentStep = step;
  };

  @computed
  get firstStep() {
    return this.currentStep === 0;
  }
  @computed
  get lastStep() {
    return this.currentStep === this.maxSteps;
  }
}
