import React from "react";
import { observer, inject } from "mobx-react";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router";

import { Button, Card, CardContent, CardActions, CardHeader, InputAdornment, TextField, withStyles, Grid } from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import Mail from "@material-ui/icons/Mail";
import Send from "@material-ui/icons/Send";

import styles from "./LoginStyles";
import Logo from "./logo.png";
import messages from "./messages";
import { ROUTE_BASE } from "../../routes/RouteList";

@withRouter
@injectIntl
@inject("routingStore", "authStore", "uiStore")
@observer
class Login extends React.Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = name => ({ target: { value } }) =>
    this.setState({
      [name]: value
    });

  handleValidation = () => {
    const { email, password } = this.state;
    return email.length > 0 && !this.validateEmail() && password.length > 4;
  };

  handleKeyPress = e => {
    //console.log(this.handleValidation())
    if (/enter/gi.test(e.key) && this.handleValidation()) {
      this.handleClick();
    }
  };

  handleClick = async () => {
    const { email, password } = this.state;
    const {
      authStore: { doLogin },
      routingStore: { push }
    } = this.props;

    const loginResult = await doLogin(email, password);

    if (loginResult === true) {
      push(ROUTE_BASE);
    }
  };

  validateEmail = () => {
    const { email } = this.state;
    if (email.length < 1) return false;
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  render() {
    const { classes, intl } = this.props;
    const { email, password } = this.state;

    return (
      <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "90vh" }}>
        <Card className={classes.card}>
          <CardHeader avatar={<img src={Logo} className={classes.avatar} alt='Logo'/>} title={intl.formatMessage(messages.title)} />
          <CardContent>
            <TextField
              required
              id="email"
              label={intl.formatMessage(messages.labelEmail)}
              helperText={intl.formatMessage(messages.labelEmailHelper)}
              value={email}
              onChange={this.handleChange("email")}
              onKeyDown={this.handleKeyPress}
              error={this.validateEmail()}
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              required
              id="password"
              label={intl.formatMessage(messages.labelPassword)}
              value={password}
              type="password"
              onChange={this.handleChange("password")}
              onKeyDown={this.handleKeyPress}
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
          </CardContent>
          <CardActions className={classes.action}>
            <Button
              id="btn_login"
              onClick={e => this.handleClick()}
              disabled={!this.handleValidation()}
              color="primary"
              className={classes.button}
              variant="contained"
            >
              <Send className={classes.extendedIcon} />
              <FormattedMessage {...messages.button} />
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
