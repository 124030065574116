import { observable, action, computed, remove, toJS } from "mobx";
import { persist } from "mobx-persist";
import { neighboorhoodLayers } from "config/config";
import { getData } from "services/mapcentia";

export default class NeighboorhoodModel {
  @persist("object")
  @observable
  services = {};

  @persist("object")
  @observable
  layers = {};

  @computed
  get layersArr() {
    return Object.entries(toJS(neighboorhoodLayers)).map(
      ([key, value]) => value
    );
  }

  @action
  updateService = (mapType, result) => {
    const { id, type } = mapType;
    if (result) {
      this.services[id] = result;
      this.loadMapData(mapType);
    } else {
      remove(this.layers, type);
      remove(this.services, id);
    }
  };

  @action
  loadMapData = async mapType => {
    const result = await getData(mapType);
    if (result && this.services[mapType.id]) {
      this.setLayerData(mapType.type, result);
    }
  };

  setLayerData = (type, result) => {
    this.layers[type] = result;
  };

  removeLayerData = type => {
    remove(this.layers, type);
  };

  isChecked = id => {
    if (this.services[id]) {
      return true;
    }
    return false;
  };
}
