export const APP_LOCALES = {
  NL: "nl",
  FR: "fr"
};

export const DEFAULT_LOCALE = APP_LOCALES.NL;

export const MAP_TYPES = {
  WMTS: "wmts",
  TILE: "tile",
  WMS: "wms"
};

export const MAP_TILE_MATRIX = {
  GOOGLE: "GoogleMapsVL",
  G20: "g20",
  GOOGLE_OLD: "EPSG:900913"
};

export const MAP_TILE_FORMAT = {
  PNG: "image/png"
};

export const MAP_CONFIG = {
  zoom: process.env.REACT_APP_MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM || 15,
  gpsButton: true
};

export const HEADER_CONFIG = {
  SEARCH_ENABLED: process.env.REACT_APP_SEARCH_ENABLED || true,
  SEARCH_RESULT_COUNT: 8
};

export const SIDEBAR_CONFIG = {
  width: 350
};

export const Z_INDEXES = {
  MAP_PUBLIC_WMTS_TILE_LAYER: 100,
  MAP_PUBLIC_TILE_LAYER: 101,
  MAP_PUBLIC_WMTS_TILE_LAYER_ORG: 102,
  MAP_ORBIT_LOGO: 1000
};
