import React from "react";

import { inject, observer } from "mobx-react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  FormGroup,
  FormControlLabel,
  Badge,
  Switch
} from "@material-ui/core";
import Slider from "@material-ui/lab/Slider";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./LayerStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

@inject("mapStore", "uiStore")
@injectIntl
@observer
class BaseLayers extends React.Component {
  selectLayer(index) {
    const {
      mapStore: {
        mapLayer: { setActiveMapIndex }
      },
      uiStore: { setSideBarRightOpen }
    } = this.props;
    setActiveMapIndex(index);
    setSideBarRightOpen(false);
  }

  render() {
    const {
      intl,
      classes,
      mapStore: {
        zoomLevel,
        publicMaps: { setOpacity, setShow, layersArr, layersList }
      }
    } = this.props;

    const changeOpacity = (layer, event, value) => {
      setOpacity(layer, Math.round(value) / 100);
    };

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            <FormattedMessage {...messages.public} />
            <Badge
              className={classes.badge}
              badgeContent={layersArr.length}
              color="primary"
            >
              {" "}
            </Badge>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: "column" }}>
          {layersArr.map(layer => (
            <FormGroup key={layer.name}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={event => setShow(layer, event.target.checked)}
                    checked={layersList[layer.name].show}
                  />
                }
                label={intl.formatMessage(messages["public" + layer.name])}
              />
              <Typography className={classes.pos} color="textSecondary">
                {intl.formatMessage(messages["transparantie"])}:
              </Typography>
              {
                <Slider
                  className={classes.pos}
                  min={0}
                  max={100}
                  value={layersList[layer.name].opacity * 100}
                  aria-labelledby="label"
                  onChange={(e, value) => changeOpacity(layer, e, value)}
                />
              }
              {(zoomLevel > layer.maxZoom || zoomLevel < layer.minZoom) && (
                <Typography className={classes.pos} color="textSecondary">
                  {intl.formatMessage(messages["zoomerror"])}
                </Typography>
              )}
            </FormGroup>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default withStyles(styles)(BaseLayers);
