import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import Party from "./model/Party";

export default class ApplicationStore {
  @persist('list', Party)
  @observable
  parties = [new Party(),new Party(),new Party()];

 
  @action
  addParty = (object) => {
    this.parties.push(new Party(object))
  };

  @action
  removeParty = id => {
    this.parties = this.parties.filter((party) => party.id !== id);

  }
}