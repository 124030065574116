import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import unique from 'uuid'

export default class Postion {
    @persist
    @observable
    id = unique();
  
    @persist
    @observable
    x="";

    @persist
    @observable
    y="";

    constructor(position) {
        if (position) {
            this.x = position.x;
            this.y = position.y
        }       
    }

    @action
    setX = (x) => {
        this.x = x
    }
    @action
    setY = (y) => {
        this.y = y
    }
}