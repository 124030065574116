import React from "react";

import { inject, observer } from "mobx-react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  FormGroup,
  FormControlLabel,
  Badge,
  Switch
} from "@material-ui/core";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./LayerStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

@inject("mapStore", "uiStore")
@injectIntl
@observer
class NeighboorhoodLayers extends React.Component {
  selectLayer(index) {
    const {
      mapStore: {
        mapLayer: { setActiveMapIndex }
      },
      uiStore: { setSideBarRightOpen }
    } = this.props;
    setActiveMapIndex(index);
    setSideBarRightOpen(false);
  }

  render() {
    const {
      intl,
      classes,
      mapStore: {
        neighboorhood: { layersArr, updateService, isChecked }
      }
    } = this.props;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            <FormattedMessage {...messages.neighborhood} />
            <Badge
              className={classes.badge}
              badgeContent={layersArr.length}
              color="primary"
            >
              {" "}
            </Badge>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: "column" }}>
          {layersArr.map(mapType => (
            <FormGroup key={mapType.id}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={event => {
                      updateService(mapType, event.target.checked);
                    }}
                    checked={isChecked(mapType.id)}
                  />
                }
                label={intl.formatMessage(messages["zone" + mapType.id])}
              />
            </FormGroup>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default withStyles(styles)(NeighboorhoodLayers);
