import React, { useContext } from "react";
import { observer as hooksObserver } from "mobx-react-lite";
import { GeoJSON, Popup } from "react-leaflet";
import { Grid } from "@material-ui/core";
import { StoresContext } from "contexts";
import { toJS } from "mobx";

const NeighborhoodMap = hooksObserver(() => {
  const {
    mapStore: {
      neighboorhood: { layers }
    }
  } = useContext(StoresContext);
  if (!layers) {
    return null;
  }

  const layerTypes = Object.entries(toJS(layers));

  return layerTypes.map(([key, value]) =>
    value.map(layer => {
      const { color, colorOpacity, colorOutline, firma, name, sector } = layer;
      return (
        <GeoJSON
          key={Math.random()}
          data={layer.location}
          style={{
            weight: 2,
            color: colorOutline,
            opacity: colorOpacity / 100,
            fillOpacity: colorOpacity / 100,
            fillColor: color,
            dashArray: "5,7"
          }}
        >
          {(firma || name) && (
            <Popup>
              <h3>{sector}</h3>
              <Grid container style={{ flexGrow: 1, minWidth: "175px" }}>
                <Grid item xs>
                  {firma && <div>{firma}</div>}
                  {name && <div>{name}</div>}
                </Grid>
              </Grid>
            </Popup>
          )}
        </GeoJSON>
      );
    })
  );
});

export default NeighborhoodMap;
