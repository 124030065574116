import { observable, action, computed} from "mobx";
import { persist } from "mobx-persist";
import CarMarker from "./CarMarker";
import unique from 'uuid'

export default class Party {
  @persist
  @observable
  id = unique();

  @persist
  @observable
  firstName = "";

  @persist
  @observable
  lastName = "";

  @persist
  @observable
  licensePlate = "";

  @persist
  @observable
  carType = "";

  @persist('list', CarMarker)
  @observable
  carMarkers = [];

  @persist
  @observable
  activeMarkerId;

  @action
  setId = id => {
    this.id = id;
  };

  @action
  serFirstName = firstName => {
    this.firstName = firstName;
  };

  @action
  setLastName = lastName => {
    this.lastName = lastName;
  };
    
  @action
  update = (id, value) => {
    this[id] = value;
  };

  @action
  addCarMarker = marker => {
    this.carMarkers.push(new CarMarker(marker))
  };

  @action
  setActiveMarkerId = id => {
    this.activeMarkerId = id;
  }

  @action
  setLicensePlate = licensePlate => {
    this.licensePlate = licensePlate;
  };

  @action
  setCarType = carType => {
    this.carType = carType;
  };

}
