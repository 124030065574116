import React from "react";
import Control from "react-leaflet-control";
import { inject, observer } from "mobx-react";
import { withLeaflet } from "react-leaflet";

import Fab from "@material-ui/core/Fab";
import GpsIcon from "@material-ui/icons/MyLocation";

import { withStyles } from "@material-ui/core/styles";
import styles from "./GPSControlStyles";

@inject("mapStore")
@observer
class GPSControl extends React.Component {
  centerMap = latlng => {
    this.props.mapStore.setLatLng(latlng);
    this.props.leaflet.map.panTo(this.props.mapStore.latLng);
  };

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(p => {
        this.centerMap({
          lat: p.coords.latitude,
          lng: p.coords.longitude
        });
      });
    } else {
      this.props.leaflet.map.panTo(this.props.mapStore.latLng);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Control position="topright">
          <Fab
            color="secondary"
            size="small"
            className={classes.fab}
            onClick={() => this.getCurrentLocation()}
          >
            <GpsIcon />
          </Fab>{" "}
        </Control>{" "}
      </div>
    );
  }
}
export default withLeaflet(withStyles(styles)(GPSControl));
