import React from "react";
import Control from "react-leaflet-control";

import { inject, observer } from "mobx-react";

import LayersIcon from "@material-ui/icons/Layers";

import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

import styles from "./LayerControlStyles";
import { injectIntl } from "react-intl";
import messages from "./messages";
import BaseLayers from "../layers/BaseLayers";
import PublicLayers from "../layers/PublicLayers";
import NeighboorhoordLayers from "../layers/NeighboorhoordLayers";

@inject("mapStore", "uiStore")
@injectIntl
@observer
class LayerControl extends React.Component {
  render() {
    const {
      classes,
      intl,
      uiStore: { sideBarRightOpen, setSideBarRightOpen }
    } = this.props;
    return (
      <div>
        <Drawer
          classes={{ root: classes.root, paper: classes.drawer }}
          anchor="right"
          open={sideBarRightOpen}
          onClose={() => setSideBarRightOpen(false)}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.toolbar}>
              {intl.formatMessage(messages.kaartlaag)}
            </Typography>
          </Toolbar>
          <div className={classes.content}>
            <BaseLayers />
            <NeighboorhoordLayers />
            <PublicLayers />
          </div>
        </Drawer>
        <Control position="topright">
          <Fab
            color="secondary"
            size="small"
            className={classes.fab}
            onClick={() => setSideBarRightOpen(true)}
          >
            <LayersIcon />
          </Fab>
        </Control>
      </div>
    );
  }
}
export default withStyles(styles)(LayerControl);
