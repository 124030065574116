import React from "react";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withStyles, Toolbar } from "@material-ui/core";

import styles from "./Step1Styles";
import EditParty from "../partials/editParty/EditParty";
import IconButton from "@material-ui/core/IconButton";
import AddPersonIcon from "@material-ui/icons/PersonAdd";
import { observer, inject } from "mobx-react";
import { FormattedMessage,injectIntl } from "react-intl";

@inject("applicationStore")
@observer
class Step1 extends React.Component {
  getPartyName = (id) => {
    return 'Partij ' + String.fromCharCode(id + 65)
  }
  
  render() {
    const { classes, applicationStore: { parties, addParty, removeParty} } = this.props;
    
    const colors = ["red", "green", "blue"];

    return (      
          <>
            <Toolbar>
              {" "}
              <Typography variant="subtitle2" gutterBottom>Stap 1: <br/> Informatie betrokken partijen</Typography>
              <div className={classes.grow} />
              <IconButton className={classes.button} onClick={()=> {addParty()}} disabled={parties.length===26}>
                <AddPersonIcon />
              </IconButton>
        </Toolbar>
        

            {parties.map((party, index) => (
              <ExpansionPanel key={index}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    style={{ color: colors[index] }}
                    className={classes.heading}
                  >
                    {this.getPartyName(index)}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <EditParty party={party} remove={removeParty}/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </>
    );
  }
}

export default injectIntl(withStyles(styles)(Step1));
